import { Document } from '@contentful/rich-text-types'
import { TextPositions } from '~/components/Content/Section/RichText'
import { IMAGE_FRAGMENT, IImage } from './image'
import { IScrollingBar, SCROLLING_BAR_FRAGMENT } from './scrolling-bar'
import type { IVariant } from './variant'
import { GridMediaItem } from '~/components/Content/Section/MediaWithTextGrid'
import { MediaSlide } from '~/components/Content/Section/MediaSlider'
import { ISlimArchive, SLIM_ARCHIVE_FRAGMENT } from './archive'
import { SLIM_EXTERNAL_PRODUCT_FRAGMENT } from './external-product'

export const PAGE_FRAGMENT = `
  name
  slug
  hero {
    name
    heading
    subheading
    image {
      ${IMAGE_FRAGMENT}
    }
    imageMobile {
      ${IMAGE_FRAGMENT}
    }
  }
  showScrollingBar
  ${SCROLLING_BAR_FRAGMENT}
  sectionsCollection(limit: 10) {
    items {
      sys {
        id
      }
      title
      slug
      description
      richDescription {
        json
      }
      hideActButton
      fullWidth
      mediaCollection(limit: 10) {
        items {
          __typename
          ... on Collection {
            sys {
              id
            }
            title
            slug
            description
            mainImage {
              ${IMAGE_FRAGMENT}
            }
          }
          ... on ExternalProduct {
            ${SLIM_EXTERNAL_PRODUCT_FRAGMENT}
          }
          ... on MediaCollection {
            sys {
              id
            }
            title
            mediaCollection(limit: 10) {
              items {
                sys {
                  id
                }
                name
                slug
                placeholder {
                  ${IMAGE_FRAGMENT}
                }
                placeholderMobile {
                  ${IMAGE_FRAGMENT}
                }
                asset {
                  ${IMAGE_FRAGMENT}
                }
                assetMobile {
                  ${IMAGE_FRAGMENT}
                }
              }
            }
          }
          ... on Product {
            sys {
              id
            }
            mainImage {
              ${IMAGE_FRAGMENT}
            }
            bastaId
            isActive
            name
            slug
            shortDescription
            variantsCollection(limit: 5) {
              items {
                name
                sku
                globalTradeItemNumber
                price
                regularPrice
                size
                weight
                mainImage {
                  ${IMAGE_FRAGMENT}
                }
              }
            }
          }
          ... on Hero {
            name
            heading
            subheading
            image {
              ${IMAGE_FRAGMENT}
            }
            imageMobile {
              ${IMAGE_FRAGMENT}
            }
          }
          ... on ContentTypeRichText {
            title
            description
            textPosition
            titleMaxWidth
          }
          ... on MediaWithTextGrid {
            title
            showTitle
            gridDescription: description {
              json
            }
            gridItemsCollection(limit: 6) {
              items {
                sys {
                  id
                }
                title
                description {
                  json
                }
                media {
                  ${IMAGE_FRAGMENT}
                }
              }
            }
          }
          ... on MediaSlider {
            autoplaySpeed
            slidesToShowMobile
            slidesToShowDesktop
            slidesCollection(limit: 5) {
              items {
                ... on MediaSlide {
                  sys {
                    id
                  }
                  title
                  media {
                    ${IMAGE_FRAGMENT}
                  }
                }
              }
            }
          }
          ... on ArchivesCollection {
            name
            enableSearch
            archivesCollection(limit: 10) {
              items {
                ${SLIM_ARCHIVE_FRAGMENT}
              }
            }
          }
        }
      }
      cta {
        json
      }
    }
  }
  withHeader
  withFooter
`

export interface IMediaCollection {
  __typename: 'MediaCollection'
  sys: {
    id: string
  }
  title: string
  mediaCollection: {
    items: {
      sys: {
        id: string
      }
      name: string
      slug: string
      placeholder: IImage
      placeholderMobile: IImage
      asset: IImage
      assetMobile: IImage
    }[]
  }
}

export interface ICollectionSection {
  __typename: 'Collection'
  sys: {
    id: string
  }
  title: string
  slug: string
  description: string
  mainImage: IImage
}

export interface IProductSection {
  __typename: 'Product'
  sys: {
    id: string
  }
  bastaId: string | null
  isActive?: boolean | null
  name: string
  slug: string
  shortDescription: string
  variantsCollection: {
    items: Omit<IVariant, 'optionValuesCollection' | 'imagesCollection'>[]
  }
}

export interface IHero {
  __typename: 'Hero'
  sys: {
    id: string
  }
  name: string
  heading: string
  subheading: string
  image: IImage
  imageMobile?: IImage
}

export interface IRichText {
  __typename: 'ContentTypeRichText'
  sys: {
    id: string
  }
  title: string
  description?: string
  textPosition: TextPositions
  titleMaxWidth?: number
}

export interface IMediaSlider {
  __typename: 'MediaSlider'
  sys: {
    id: string
  }
  title: string
  autoplaySpeed: number
  slidesToShowMobile: number
  slidesToShowDesktop: number
  slidesCollection: {
    items: MediaSlide[]
  }
}

export interface IMediaWithTextGrid {
  __typename: 'MediaWithTextGrid'
  sys: {
    id: string
  }
  title: string
  showTitle: boolean
  gridDescription?: { json: Document }
  gridItemsCollection: {
    items: GridMediaItem[]
  }
}

export interface IArchivesCollection {
  __typename: 'ArchivesCollection'
  sys: {
    id: string
  }
  enableSearch: boolean
  archivesCollection: {
    items: ISlimArchive[]
  }
}

export type IMediaSection =
  | ICollectionSection
  | IProductSection
  | IHero
  | IRichText
  | IMediaCollection
  | IMediaWithTextGrid
  | IMediaSlider
  | IArchivesCollection

export interface ISection {
  sys: {
    id: string
  }
  hideActButton: boolean
  fullWidth?: string
  title: string
  slug: string
  description: string
  richDescription?: {
    json: string
  }
  mediaCollection: {
    items: IMediaSection[]
  }
  cta
}

export interface IPage {
  name: string
  slug: string
  hero: IHero
  showScrollingBar: boolean
  scrollingBarCollection: {
    items: IScrollingBar[]
  }
  sectionsCollection: {
    items: ISection[]
  }
  withHeader: boolean
  withFooter: boolean
}
